@charset "utf-8";

// add custom
// $background-color: #252a34 !default;
// $text-color: #eaeaea !default;
// $text-color: #002010 !default;
// $background-color: #eaeaea !default;
// $primary-color: #006030 !default;
// $link-color: mix($primary-color, $text-color, 40%) !default;
// $link-color-hover: mix(#fff, $link-color, 25%) !default;
// $link-color-visited: mix(#000, $link-color, 25%) !default;
$paragraph-indent: true;
// end custom

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials
